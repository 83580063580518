import React from "react"
import Layout from "../../components/layout"
import { Container } from "reactstrap"
import Head from "../../components/head"
import { Link } from "gatsby"



const liStyle = {
  display: "list-item",
  listStyleType: "disc",
  paddingBottom: ".5rem",
  marginLeft: "2rem",
  fontSize: "14px",
  color: "#777777",
}
const Upgrades = () => {
  return (
    <Layout>
      <Head title="Upgraded Ventriloquist Dummies" description="All about our ventriloquist dummmies and the upgraded versions and what sets them apart." />
      <Container>
        <section className="about-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-section">
                </div>
              </div>
              <div className="col-sm-12">
                <h4>ABOUT OUR VENTRILOQUIST DOLLS, UPGRADED VENTRILOQUIST DUMMIES AND WHAT SETS THEM APART</h4>

                <p>When it's time to buy a ventriloquist dummy ThrowThings.com is the place to go - whether you are just starting out, are a seasoned pro, or need a dummy for a stage show like "Annie", a television show, or for a special event. ThrowThings.com is the number one seller of these dummies and has a combination of pricing and service that can't be beat. All versions come with the "Seven Simple Steps To Ventriloquism" pamphlet. For more in depth instruction check out our instructional books, videos and DVD's that can help you learn ventriloquism.</p>
                <p>We offer several different versions of the 12 celebrity ventriloquist dummies. You can get a Basic Ventriloquist Doll, Standard Upgrade Ventriloquist Dummy, Deluxe Upgrade Ventriloquist Dummy or Super Deluxe Upgrade Ventriloquist Dummy. The video and information below will show and tell you the differences so that you can decide which level of ventriloquist dummy is right for you.</p>
                <p>Please click on the arrow below to watch a short video introduction to our ventriloquist dummies. More information below.</p>
                <iframe title="An Introduction To Our Ventriloquist Dummies" width="320" height="265" src="https://www.youtube-nocookie.com/embed/33u9yc72gYE?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                {/* <iframe title="An Introduction To Our Ventriloquist Dummies" width="560" height="315" src="https://www.youtube-nocookie.com/embed/33u9yc72gYE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                <h4>BASIC VENTRILOQUIST DOLLS</h4>
                <p>The basic ventriloquist dolls have a stuffed body with plastic head and hands and have a string in the back of the neck that you pull to make the mouth move. These dummies are approximately 30" tall except for Lester who is 26" and Laurel and Hardy who are 24". Great for collectors and fun for kids, but somewhat tough to use in performance and the heads do not turn on these dolls. The eyes do not move and hair is plastic. Unlike some other companies that might sell these celebrity dummy dolls, our basic ventriloquist dolls come in their own vinyl carrying case! (Upgrades do not as they will not fit properly into the cases once upgraded.) Starting at $59.99 </p>
                <h4>STANDARD UPGRADE VENTRILOQUIST DUMMIES</h4>
                <p>Starting with the basic ventriloquist doll, our figure makers add a hard hollow body and the head is mounted to a control stick. You are able to put your hand in the back and control the mouth with a lever on the control stick. The head can tilt and turn all the way around. One hand operation while the dummy sits on your lap or on a table, stool, etc.  From $174.99</p>
                <h4>DELUXE UPGRADE VENTRILOQUIST DUMMIES</h4>
                <p>Like the Standard Upgrade, this version starts with the basic ventriloquist doll. Our figure makers add a hard hollow body and the head is mounted to a control stick. Also added are moving eyes and a wig. You are able to put your hand in the back and control the mouth and eyes with control on the control stick. The head can tilt and turn all the way around. From $294.99</p>
                <h4>SUPER DELUXE UPGRADE VENTRILOQUIST DUMMIES</h4>
                <p>Moving eyebrows in addition to moving eyes, lifelike wig, and all the other features of the Deluxe Upgrade Ventriloquist Dummies make this version truly Super. Like the Standard and Deluxe Upgrades, our figure makers start with the basic ventriloquist doll and add a hard hollow body with the head mounted to a control stick. Also added are moving eyes, moving eyebrows, and a wig. You are able to put your hand in the back and control the mouth, eyes and eyebrows with  controls on the control stick. The head can tilt and turn all the way around. From $384.99</p>
                <h4>GRAMPS AND GRANNY VENTRILOQUIST DUMMIES</h4>
                <p>New from ThrowThings are the Gramps and Granny ventriloquist dummy characters. If you've been looking for an old man (grandpa) or old woman (grandma) ventriloquist dummy or are just looking for a different character these are for you. These 30" ventriloquist dummies are available in three levels (Classic, Supreme, and Ultra Supreme) each has a hard hollow body so that you can put your hand in the back and turn the head, trigger action mouth, wig, special clothing and glasses. The Supreme level adds self centering moving eyes. The Ultra Supreme has self centering moving eyes AND moving eyebrows.  $224.99 to $399.99 depending on the level you select.</p>
                <h4>STANDARD UPGRADE CONVERTED FEMALE VENTRILOQUIST DUMMIES</h4>
                <p>All the same features as the Standard Upgrade Ventriloquist Dummies, except that these basic ventriloquist dolls are converted to female figures during the upgrade process. Includes hand made custom dress and bloomers, special wig, and eyelashes. Starting with the basic ventriloquist doll, our figure makers add a hard hollow body and the head is mounted to a control stick. You are able to put your hand in the back and control the mouth with a lever on the control stick. The head can tilt and turn all the way around. One hand operation while your ventriloquist dummy sits on your lap or on a table, stool, etc.  From $194.99</p>
                <h4>DELUXE UPGRADE CONVERTED FEMALE VENTRILOQUIST DUMMIES</h4>
                <p>All the same features as the Deluxe Upgrade Ventriloquist Dummies, except that these basic ventriloquist dolls are converted to female figures during the upgrade process. Includes hand made custom dress and bloomers, special wig, and eyelashes.  Starting with the basic ventriloquist dolls, our figure makers add a hard hollow body and the head is mounted to a control stick. You are able to put your hand in the back and control the mouth with a lever on the control stick. The head can tilt and turn all the way around. One hand operation while your ventriloquist dummy sits on your lap or on a table, stool, etc.  From $294.99</p>
                <h4>PROFESSIONAL VENTRILOQUIST DUMMIES</h4>
                <p>Check out our new line of Professional Ventriloquist Dummies! Built from scratch these pro figures feature carved wooden heads and hands, deluxe wigs, great 38" size (approx), clothing as shown, moving eyes, and much more! No two are exactly alike. SAVE NOW with our great Introductory Offers. Limited quantities available due to the great amount of time that goes into each of these wonderful ventriloquist dummies. $1199.00 - 1499.00</p>
                <h4>WHAT MAKES OUR UPGRADED VENTRILOQUIST DUMMIES SO SPECIAL?</h4>
                <p>We're not alone in thinking that our upgraded ventriloquist dummies are the best in the business. Before you consider buying an upgraded celebrity ventriloquist dummy from someone else, consider that...</p>
                <ul>
                  <li style={liStyle}>
                    Our upgraded mouth mechanisms are created with springs, metal and wood pieces. Others use materials like rubber bands and elastic.
                  </li>
                  <li style={liStyle}>
                    Our hard hollow bodies are all wood wrapped with material. Others use some or no wood with cardboard or have only a partial shell.
                  </li>
                  <li style={liStyle}>
                    Our head posts are made of wood, not the pvc (plastic) posts that others use.
                  </li>
                </ul>
                <p>Before you consider buying from someone else, we suggest you ask about these important issues.</p>
                <section className="section-b-space sitemap_page">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-3 col-6">
                        <ul className="">
                          <li>
                            <Link to="/dummies/available">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              CLICK HERE TO CHECK AVAILABILITY
                            </Link>
                          </li>
                        </ul>
                        <h4>Or check out the categories:</h4>
                        <ul>
                          <li>
                            <Link to="/basic-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Basic Ventriloquist Dolls
                            </Link>
                          </li>
                          <li>
                            <Link to="/standard-upgrade-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Standard Upgrade Ventriloquist Dummies
                            </Link>
                          </li>
                          <li>
                            <Link to="/deluxe-upgrade-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Deluxe Upgrade Ventriloquist Dummies
                            </Link>
                          </li>
                          <li>

                            <Link to="/super-deluxe-upgrade-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Super Deluxe Upgrade Ventriloquist Dummies
                            </Link>
                          </li>
                          <li>
                            <Link to="/professional-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Professional Ventriloquist Dummies
                            </Link>
                          </li>
                          <li>
                            <Link to="/limited-editions">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Limited Collector's Edition Opening Night Series Upgrades
                            </Link>
                          </li>
                          <li>
                            <Link to="/female-ventriloquist-dummies">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Standard & Deluxe Upgrade Converted Female Ventriloquist Dummies
                            </Link>
                          </li>
                          <li>
                            <Link to="/books-dvds-cases-and-stands">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Books, Videos, DVD's & Cases
                            </Link>
                          </li>
                          <li>
                            <Link to="/marionettes">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Czech Marionettes
                            </Link>
                          </li>
                          <li>
                            <Link to="/hand-puppets">
                              <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                              Hand Puppets
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default Upgrades
